import {makeActivityRenderer} from "../activity-renderer-factory.js";

export const eventNotificationActivityRenderer = makeActivityRenderer(
	activity => {
		// Get WP's translation function.
		const translate = window.wp.i18n.__;

		// Get the meta of the activity.
		const meta = activity?.meta || {};

		const title = translate('Event notification sent', 'ams-common');

		return `
                <strong>${title}</strong><br>
                <small>Event: ${meta.event_title || translate('Unknown event.')}</small><br>
                <small>Event ID: ${meta.event_id || translate('Unknown event ID.')}</small>
            `;
	},
	{
		iconFramed: true,
		icon: window.Groundhogg?.element?.icons?.email || '📧',
	}
)
