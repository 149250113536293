import {amsPicker} from "../ams-picker.js";

/**
 * Create a filter. The GH data store will be retained in the picker, if that's needed externally.
 */
export function makeTeamNamesFilter() {
	if (!wp?.i18n) {
		throw new Error('WordPress is not loaded, or could not get the i18n functions.');
	}

	if (!Groundhogg?.filters?.functions) {
		throw new Error('Groundhogg is not loaded, or could not get the filters functions.');
	}

	// Get some formatting/localisation functions from WP.
	const {__, sprintf} = wp.i18n

	// Get formatting functions from Groundhogg.
	const {select, bold, orList} = Groundhogg.element;

	// Attempt to get the AJAX route from the page.
	const route = amsGroundhogg?.team_names_search_route;

	if (!route) {
		throw new Error('Could not get the team names search route.');
	}

	// Get the store creator from Groundhogg.
	const {createStore} = Groundhogg;

	if (!createStore) {
		throw new Error('Groundhogg is not loaded, or could not get the store creator.');
	}

	// Create a store for the picker. This is what handles the AJAX requests/etc.
	const store = createStore(
		'ams-team-names',
		route,
		{
			primaryKey: 'id',
			getItemFromResponse: (result) => result,
			getItemsFromResponse: (results) => results,
		}
	);

	return {
		view({team_ids, ...props}) {
			let team = __('<strong>any group</strong>', 'ams-common');

			if (team_ids.length) {
				team = orList(team_ids.map(index => bold(store.get(index).name)));
			}

			return sprintf(__('Group %s', 'ams-common'), team);
		},
		edit({team_ids, ...props}) {
			// Get the props for the select.
			const selectProperties = {
				id: 'filter-team-id',
				name: 'team_ids',
				multiple: true,
			};

			// Get the items for the select, falling back to an empty array if need be (eg, pre mount condition).
			const items = store.getItems().map(team => ({
				text: team.name,
				value: team.id,
			})) || [];

			return ` ${select(selectProperties, items, team_ids)} `;
		},
		onMount(filter, updateFilter) {
			// Create the picker element - this will find the select box and replace it with a nice search/select UI.
			const picker = amsPicker(
				'#filter-team-id',
				store,
				{
					placeholder: __('Please select one or more groups', 'ams-common'),
				}
			);

			picker.on('change', e => {
				// Update the filter with the new team ids.
				updateFilter({
					team_ids: jQuery(e.target).val().map(id => parseInt(id)),
				})
			})
		},
		defaults: {
			team_ids: [],
		},
		preload: ({team_ids}) => {
			// Pre-fetch the teams from the selected team_ids, if there are any.
			return store.fetchItems({
				include: team_ids,
			}) || [];
		},
	};
}
