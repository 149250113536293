import {amsPicker} from "../ams-picker.js";

/**
 * Create a filter. The GH data store will be retained in the picker, if that's needed externally.
 */
export function makeEventsWithAttendeesFilter() {
	if (!wp?.i18n) {
		throw new Error('WordPress is not loaded, or could not get the i18n functions.');
	}

	if (!Groundhogg?.filters?.functions) {
		throw new Error('Groundhogg is not loaded, or could not get the filters functions.');
	}

	// Get some formatting/localisation functions from WP.
	const {__, sprintf} = wp.i18n

	// Get formatting functions from Groundhogg.
	const {select, bold, orList} = Groundhogg.element;

	// Attempt to get the AJAX route from the page.
	const route = amsGroundhogg?.events_with_attendees_search_route;

	if (!route) {
		throw new Error('Could not get the events with attendees search route.');
	}

	// Get the store creator from Groundhogg.
	const {createStore} = Groundhogg;

	if (!createStore) {
		throw new Error('Groundhogg is not loaded, or could not get the store creator.');
	}

	// Create a store for the picker. This is what handles the AJAX requests/etc.
	const store = createStore(
		'ams-events',
		route,
		{
			primaryKey: 'id',
			getItemFromResponse: (result) => result,
			getItemsFromResponse: (results) => results,
		}
	);

	return {
		view({event_ids, ...props}) {
			let events = __('<strong>any event</strong>', 'ams-common');

			if (event_ids.length) {
				events = orList(event_ids.map(index => bold(store.get(index).name)));
			}

			return sprintf(__('Attending %s', 'ams-common'), events);
		},
		edit({event_ids, ...props}) {
			// Get the props for the select.
			const selectProperties = {
				id: 'filter-event-id',
				name: 'event_ids',
				multiple: true,
			};

			// Get the items for the select, falling back to an empty array if need be (eg, pre mount condition).
			const items = store.getItems().map(event => ({
				text: event.name,
				value: event.id,
			})) || [];

			return ` ${select(selectProperties, items, event_ids)} `;
		},
		onMount(filter, updateFilter) {
			// Create the picker element - this will find the select box and replace it with a nice search/select UI.
			const picker = amsPicker(
				'#filter-event-id',
				store,
				{
					placeholder: __('Please select one or more events', 'ams-common'),
				}
			);

			picker.on('change', e => {
				// Update teh filter with the new event_ids.
				updateFilter({
					event_ids: jQuery(e.target).val().map(id => parseInt(id)),
				})
			})
		},
		defaults: {
			event_ids: [],
		},
		preload: ({event_ids}) => {
			// Pre-fetch the events from the selected event_ids, if there are any.
			return store.fetchItems({
				include: event_ids,
			}) || [];
		},
	};
}
