export function setupLedgerRebuildButtons() {
	const $candidates = document.querySelectorAll('.ams-finance-rebuild-action');

	if ($candidates.length === 0) {
		return;
	}

	$candidates.forEach($candidate => {
		$candidate.addEventListener('submit', event => {
			if (!confirm("Are you sure you wish do this?")) {
				event.preventDefault();
				event.stopImmediatePropagation();
				return false;
			}
		});
	});

}
