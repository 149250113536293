import {makeActivityRenderer} from "../activity-renderer-factory.js";

export const uploadFormFileToContactActivityRenderer = makeActivityRenderer(
	activity => {
		// Get WP's translation function.
		const translate = window.wp.i18n.__;

		// Get the meta of the activity.
		const meta = activity?.meta || {};

		const title = translate(`File uploaded from form: <em>${meta.form_title} &raquo; ${meta.field_label}</em>`, 'ams-common');

		return `
				<h6>${title}</h6>
				<table>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-success-row">
					<th>Success</th>
					<td>${meta.success ? 'true' : 'false'}</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-message-row">
					<th>Message</th>
					<td>${meta.message || '(no message)'}</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-original-filename-row">
					<th>Original Filename</th>
					<td>
						<a onclick="document.querySelector('#secondary-tabs .nav-tab[data-tab=files]').click()">
							${meta.original_filename || translate('Original filename unknown.')}
						</a>
					</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-updated-filename-row">
					<th>Updated Filename</th>
					<td>
						<a onclick="document.querySelector('#secondary-tabs .nav-tab[data-tab=files]').click()">
							${meta.updated_filename || translate('Updated filename unknown.')}
						</a>
					</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-form-row">
					<th>Form</th>
					<td>${meta.form_title || translate('Form title unknown')} (ID: ${meta.form_id || translate('Form ID unknown')})</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-field-row">
					<th>Field</th>
					<td>${meta.field_label || translate('Field label unknown')} (ID: ${meta.field_id || translate('Field ID unknown')})</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-feed-row">
					<th>Feed</th>
					<td>${meta.feed_name || translate('Feed name unknown')} (ID: ${meta.feed_id || translatea('Feed ID unknown')})</td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-entry-row">
					<th>Entry</th>
					<td><a href="${encodeURI(meta.entry_link || translate('Entry link missing'))}">View entry</a></td>
				</tr>
				<tr class="ams-upload-form-file-to-contact-activity-renderer-view-file-row">
					<th>View file</th>
					<td><a onclick="document.querySelector('#secondary-tabs .nav-tab[data-tab=files]').click()">View file</a></td>
				</tr>
				</table>
			`;
	},
	{
		iconFramed: true,
		icon: window.Groundhogg?.element?.icons?.save || '📂',
	}
);
