
/**
 * Make the SKU field required.
 */
export function makeWoocommerceProductSkuFieldRequired() {
	const sku = document.querySelector('input#_sku');
	const label = document.querySelector('label[for="_sku"]');

	// Find the parent form.
	const form = sku?.closest('form');

	const inventoryTab = document.querySelector('ul.wc-tabs li.inventory_tab');
	const anchor = document.querySelector('ul.wc-tabs li.inventory_tab a');
	const publishButton = document.querySelector('input#publish,button#publish');

	// If the elements are not found, return.
	if (!sku || !label || !form || !inventoryTab || !anchor || !publishButton) {
		return;
	}

	// Make the SKU field required.
	sku.required = true;

	// Append an asterisk to the label.
	const req = document.createElement('span');
	const space = document.createTextNode(' ');
	req.classList.add('required');
	req.textContent = '*';
	label.appendChild(space);
	label.appendChild(req);

	// On submit scroll the sku field into view if it's invalid.
	publishButton.addEventListener('click', () => {
		// If the sku is not valid, scroll it into view.
		if (!sku.checkValidity()) {
			// If the inventory tab is not active, simulate a click to make it active.
			if (!inventoryTab.classList.contains('active')) {
				anchor.click();
			}

			sku.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	});
}
