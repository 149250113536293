/**
 * @typedef {Object} ActivityRendererOptions Activity renderer options. The required options are described here, but you could
 * add any other options you want, as it is spread into the renderer. This means we can support extra options now and in the future.
 * @property {string} icon The icon for the activity.
 * @property {boolean} iconFramed Whether the icon should be framed or not.
 */

/**
 * @typedef {ActivityRendererOptions} ActivityRenderer An activity renderer. This is the object that is passed to GroundHogg's ActivityTimeline.addType() method.
 * @property {function} render The callback which renders the activity. It is passed the activity details, and should return a string.
 */



/**
 * Make a renderer for GroundHogg's contact activity timeline.
 *
 * @param {function} renderCallback The callback which renders the activity. It is passed the activity details, and should return a string.
 * @param {ActivityRendererOptions} options Options for the renderer. You'll probably want to provide
 *
 * @return {ActivityRenderer} The activity renderer.
 */
export function makeActivityRenderer(renderCallback, options = {}) {
	if (!renderCallback) {
		throw new Error('You must provide a render callback.');
	}

	const activity = {
		...options,
		render: (activity) => renderCallback(activity),
	}

	// Ensure the required attributes are present.
	activity.icon = activity.icon || '?';
	activity.iconFramed = activity.iconFramed || false;

	return activity;
}
