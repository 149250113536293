function groupMembershipPageSlugs() {
	const slugs = window.amsGroupMembershipManager?.page_slugs || [];

	return Array.isArray(slugs) ? slugs : [];
}

/**
 * Highlight the membership menu when on membership admin pages.
 *
 * @returns {void}
 */
export function highlightMembershipMenu() {
	if (!window.amsGroupMembershipManager) {
		return;
	}

	const currentPage = `${window.location.pathname}${window.location.search}`;

	// If the current page includes any of the pages, add active state to Membership menu.
	if (isMembershipAdminPage(currentPage)) {
		// Remove active state from current active menu.
		document.querySelectorAll('#adminmenu .wp-has-current-submenu').forEach(element => {
			element.classList.remove('wp-has-current-submenu');
			element.classList.add('wp-not-current-submenu');
		});

		const parentMenuId = `toplevel_page_${window.amsGroupMembershipManager.parent_menu_slug}`;
		const $parentMenu = document.querySelector(`#${parentMenuId}`);

		if ($parentMenu) {
			$parentMenu.classList.add('wp-has-current-submenu');
			$parentMenu.classList.remove('wp-not-current-submenu');
			$parentMenu.querySelector('a').classList.add('wp-has-current-submenu');
		}
	}
}

/**
 * Check if the current page is a membership admin page.
 * @param {string} currentPage Current page URL.
 *
 * @returns {boolean}
 */
function isMembershipAdminPage(currentPage) {
	const candidates = groupMembershipPageSlugs().map(slug => `admin.php?page=${slug}`);

	return candidates.some(candidate => currentPage.includes(candidate));
}
