import {makeActivityRenderer} from "../activity-renderer-factory.js";
import {icons} from '../../../shared/icons.js';

export const wooCommerceAddToCartActivityRenderer = makeActivityRenderer(
	activity => {
		// Get WP's translation function.
		const translate = window.wp.i18n.__;
		const sprintf = window.wp.i18n.sprintf;

		// Get the meta of the activity.
		const meta = activity?.meta || {};

		const product_id = meta?.product_id;
		const quantity = meta?.quantity;

		if (!product_id || !quantity ) {
			return translate('Unknown product.', 'ams-common');
		}
		let message = sprintf(
			// translators: 1: quantity, 2: product id
			translate('Product added to cart - %1$d x %2$d'),
			quantity,
			product_id
		);

		return `<a href="/wp-admin/post.php?post=${product_id}&action=edit">${message}</a>`;
	},
	{
		iconFramed: true,
		icon: icons.shoppingCart
	}
)
