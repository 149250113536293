import {eventNotificationActivityRenderer} from "./activities/event-notification.js";
import {uploadFormFileToContactActivityRenderer} from "./activities/upload-form-file-to-contact.js";
import {uploadFormFileToContactFailedActivityRenderer} from "./activities/upload-form-file-to-contact-failed.js";
import {contactFolderCreationFailedActivityRenderer} from "./activities/contact-folder-creation-failed.js";
import {wooCommerceAddToCartActivityRenderer} from "./activities/woo-add-to-cart.js";
import {wooCommerceOrderActivityRenderer} from "./activities/woo-order.js";


export function setupActivityRenderers() {
	// No groundhogg activity timeline, bail...
	if (!window.Groundhogg?.ActivityTimeline?.types) {
		return;
	}

	// No wp functions, bail...
	if (!window.wp?.i18n?.sprintf) {
		return;
	}

	// Register our renderers.
	Groundhogg.ActivityTimeline.addType('ams_event_notification', eventNotificationActivityRenderer);
	Groundhogg.ActivityTimeline.addType('upload_form_file_to_contact', uploadFormFileToContactActivityRenderer);
	Groundhogg.ActivityTimeline.addType('upload_form_file_to_contact_failed', uploadFormFileToContactFailedActivityRenderer);
	Groundhogg.ActivityTimeline.addType('error_creating_folder_for_contact', contactFolderCreationFailedActivityRenderer);
	Groundhogg.ActivityTimeline.addType('woo_add_to_cart', wooCommerceAddToCartActivityRenderer);
	Groundhogg.ActivityTimeline.addType('woo_order', wooCommerceOrderActivityRenderer );

};
