/**
 * Hide all the parameter groups for a report.
 *
 * @param {HTMLElement} $container The container for the report selector.
 */
function hideAllReportParameterGroups($container) {
	const parameterGroupClass = 'ams-common-report-parameters';
	$container.querySelectorAll('.' + parameterGroupClass)
		// .forEach($fieldset => {
		// 	$fieldset.classList.add(`${parameterGroupClass}--hidden`);
		// })
		.forEach(hideParameterGroup);
}

/**
 * Given a parameter group container, hide it and set all fields to disabled. We don't want those
 * fields submitted.
 *
 * @param $container The container to hide.
 */
function hideParameterGroup($container) {
	$container.classList.add('ams-common-report-parameters--hidden');
	$container.querySelectorAll('input, select, textarea')
		.forEach($field => {
			$field.disabled = true;
		});
}

/**
 * Reveal the parameter group, and remove all disable attributes from fields.
 *
 * @param $container The container to show.
 */
function showParameterGroup($container) {
	$container.classList.remove('ams-common-report-parameters--hidden');
	$container.querySelectorAll('input, select, textarea')
		.forEach($field => {
			$field.disabled = false;
		});
}

/**
 * Set up a report selector element. Watches for changes, and tries to show/hide parameters
 * for the report, if those are present.
 *
 * @param {HTMLElement} $container The container for the report selector.
 */
function setupReportSelector($container) {
	const $parent = $container.closest('.ams-common-report-configuration');

	if (!$parent) {
		console.warn('No logical parent found for ', $container);
		return;
	}

	// Watch change events from report selections and show/hide parameters as needed.
	$container.addEventListener('input', event => {
		// Bail if it's not a report option.
		if (!event.target.matches('[data-ams-common-report-option]')) {
			console.warn('not viable target');
			return;
		}

		// Hide all the parameter groups by default.
		hideAllReportParameterGroups($parent);

		// Not checked, bail.
		if (!event.target.matches(':checked')) {
			return;
		}

		// ...and show the one that matches the selected report.
		const selector = `.ams-common-report-parameters[data-ams-common-report-name="${event.target.value}"]`;
		const $selected = $parent.querySelector(selector);

		if ($selected) {
			showParameterGroup($selected);
		}
	});

	// Trigger the change event to show the correct parameters to start with. Prefer the currently selected
	// report so those controls are shown if the form was posted/pre-selected, otherwise just use the first.
	const $selectedReport = $container.querySelector('[data-ams-common-report-option]:checked');
	const $firstReport = $container.querySelector('[data-ams-common-report-option]');
	($selectedReport || $firstReport)?.dispatchEvent(
		new Event('input', {bubbles: true})
	);
}

export function setupReportSelectors() {
	const $candidates = document.querySelectorAll('.ams-common-report-selector');

	// No candidates, bail.
	if ($candidates.length === 0) {
		return;
	}

	// Set up the selector.
	$candidates.forEach(setupReportSelector);
}
