/**
 * Get an AMS Picker for Groundhogg. It is effectively a GH API Picker which maps
 * results to id and name. This code is derived from GH code and mimics their pattern,
 * my (current) familiarity of their codebase is not great, so not 100% on their API
 * and best patterns/practices at time of writing.
 *
 * @param selector The selector to use for the picker.
 * @param store The data store.
 * @param options The options for the query.
 * @return {*} The picker.
 */
export function amsPicker(selector, store, ...options) {
	if (!Groundhogg?.pickers) {
		throw new Error('Groundhogg pickers not found.');
	}

	// Get the GH API picker from their pickers.
	const apiPicker = Groundhogg?.pickers?.apiPicker;

	if (!apiPicker) {
		throw new Error('Groundhogg API picker not found.');
	}

	// Make an API picker from the GH API picker.
	const picker = apiPicker(selector, store.route, true, false,
		(results) => {
			store.itemsFetched(results)
			return results.map(result => ({
				id: result.id,
				text: result.name
			}))
		},
		(query) => ({
			search: query.term,
			per_page: 20
		}), ...options);

	// Put a helpful store reference on the picker object.
	picker.store = store;

	return picker;
}
