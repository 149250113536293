import {makeActivityRenderer} from "../activity-renderer-factory.js";
import {icons} from '../../../shared/icons.js';

export const wooCommerceOrderActivityRenderer = makeActivityRenderer(
	activity => {
		// Get WP's translation function.
		const translate = window.wp.i18n.__;
		const sprintf = window.wp.i18n.sprintf;

		// Get the meta of the activity.
		const meta = activity?.meta || {};
		const data = activity?.data || {};

		const order_id = meta?.order_id;

		if (!order_id) {
			return translate('Unknown order.', 'ams-common');
		}

		let message = sprintf(
			// translators: 1: order_id
			translate('Order created - #%1$d'),
			order_id
		);


		// If price is included, append it to the message.
		if (data.value) {
			message += ` for $${data.value}`;
		}

		return `<a href="/wp-admin/post.php?post=${order_id}&action=edit">${message}</a>`;
	},
	{
		iconFramed: true,
		icon: icons.store
	}
)
