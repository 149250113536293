import {amsPicker} from "../ams-picker.js";

/**
 * Create a filter. The GH data store will be retained in the picker, if that's needed externally.
 */
export function makePurchaseWithProductTaxonomyFilter() {
	if (!wp?.i18n) {
		throw new Error('WordPress is not loaded, or could not get the i18n functions.');
	}

	if (!Groundhogg?.filters?.functions?.standardActivityDateFilterOnMount) {
		throw new Error('Groundhogg is not loaded, or could not get the filters functions.');
	}

	// Get some formatting/localisation functions from WP.
	const {__, sprintf} = wp.i18n

	// Get formatting functions from Groundhogg.
	const {select, bold, orList} = Groundhogg.element;

	// Attempt to get the AJAX route from the page.
	const route = amsGroundhogg?.purchase_with_product_taxonomy;

	if (!route) {
		throw new Error('Could not get the purchase with product taxonomy search route.');
	}

	// Get the store creator from Groundhogg.
	const {createStore} = Groundhogg;

	if (!createStore) {
		throw new Error('Groundhogg is not loaded, or could not get the store creator.');
	}

	// Create a store for the picker. This is what handles the AJAX requests/etc.
	const store = createStore(
		'ams-purchase-with-product-taxonomy',
		route,
		{
			primaryKey: 'id',
			getItemFromResponse: (result) => result,
			getItemsFromResponse: (results) => results,
		}
	);

	return {
		view({taxonomy_ids, ...props}) {
			let taxonomies = __('<strong>any taxonomy</strong>', 'ams-common');

			if (taxonomy_ids.length) {
				taxonomies = orList(taxonomy_ids.map(index => bold(store.get(index).name)));
			}

			return Groundhogg.filters.functions.standardActivityDateTitle(
				sprintf(__('With taxonomy %s', 'ams-common'), taxonomies),
				props
			);
		},
		edit({taxonomy_ids, ...props}) {
			// Get the props for the select.
			const selectProperties = {
				id: 'filter-purchase-with-product-taxonomy-id',
				name: 'taxonomy_ids',
				multiple: true,
			};

			// Get the items for the select, falling back to an empty array if need be (eg, pre mount condition).
			const items = store.getItems().map(event => ({
				text: event.name,
				value: event.id,
			})) || [];

			return `
				${select(selectProperties, items, taxonomy_ids)}
				${ Groundhogg.filters.functions.standardActivityDateOptions(props) }
			 `;
		},
		onMount(filter, updateFilter) {
			// Create the picker element - this will find the select box and replace it with a nice search/select UI.
			const picker = amsPicker(
				'#filter-purchase-with-product-taxonomy-id',
				store,
				{
					placeholder: __('Please select one or more taxonomies', 'ams-common'),
				}
			);

			picker.on('change', e => {
				// Update the filter with the new taxonomy_ids.
				updateFilter({
					taxonomy_ids: jQuery(e.target).val().map(id => parseInt(id)),
				})
			})

			Groundhogg.filters.functions.standardActivityDateFilterOnMount(filter, updateFilter)
		},
		defaults: {
			taxonomy_ids: [],
			...Groundhogg.filters.functions.standardActivityDateDefaults
		},
		preload: ({taxonomy_ids}) => {
			// Pre-fetch the events from the selected taxonomy_ids, if there are any.
			return store.fetchItems({
				include: taxonomy_ids,
			}) || [];
		},
	};
}
