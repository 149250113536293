import {setupStateSelection} from "./individual-membership-manager.js";

const groundhoggContacts = [];

/**
 * Check if an email address is valid by returning the validation error message.
 *
 * @param {string} email
 * @return string The error message, or empty string if OK.
 */
function getEmailValidationError(email) {
	getEmailValidationError.__$emailValidatorInput.value = email;
	const result = getEmailValidationError.__$emailValidatorInput.validationMessage;
	getEmailValidationError.__$emailValidatorInput.value = '';
	return result;
}

getEmailValidationError.__$emailValidatorInput = document.createElement('input');
getEmailValidationError.__$emailValidatorInput.value = '';
getEmailValidationError.__$emailValidatorInput.setAttribute('type', 'email');

/**
 * Set up the membership forms.
 *
 * @return void
 */
export function setupGroupMembershipForms() {
	// Bail if it doesn't look like a group membership page.
	if (!window.amsGroupMembershipManager) {
		return;
	}

	const $seatFieldCandidates = document.querySelectorAll('.ams-common-group-admin-field input[name=number_of_seats]');
	$seatFieldCandidates.forEach(setupNumberOfSeatsField);

	const $editGroupForm = document.querySelector('form#ams_edit_group');
	if ($editGroupForm) {
		addContactDropdown($editGroupForm);
		setupStateSelection($editGroupForm);
	}

	document.querySelector('.ams-common-add-new-group-members-button')?.addEventListener('click', showAddMembersForm);
	// addToggleForAddNewGroupMembersForm('.ams-common-add-new-group-members-button');
	addRepeaterForAddNewGroupMembersForm('#add_group_member', '#add_group_members', '#new_group_member_row_template');
}

/**
 * Set up the number of seats field.
 *
 * @param {HTMLInputElement} $seatsField
 */
function setupNumberOfSeatsField($seatsField) {
	const $form = $seatsField.closest('form');

	if (!$form) {
		return;
	}

	const $membershipTypeChoices = $form.querySelectorAll('input[type=radio][name=membership_type]');

	// We need the type field.
	if (!$membershipTypeChoices.length) {
		return;
	}

	$membershipTypeChoices.forEach($choice => $choice.addEventListener('change', () => setNumberOfSeatsBasedOnMembershipType($form)));
	setNumberOfSeatsBasedOnMembershipType($form);
}

/**
 * Add the contact dropdown to the specified form.
 *
 * @param {HTMLFormElement} $form The form to add contact dropdown to.
 * @return void
 */
function addContactDropdown($form) {
	if (!window['groundhogg_endpoints']?.contacts) {
		console.warn('Groundhogg contacts endpoint was missing.');
		return;
	}

	const $selectElement = $form.querySelector('select[name=existing_contact]');

	if (!$selectElement) {
		return;
	}

	// Add select2 to the existing contact dropdown.
	/* global jQuery */
	let $existingContact = jQuery($selectElement);
	$existingContact.select2(
		{
			width: null,
			allowClear: true,
			ajax: {
				/* global groundhogg_endpoints */
				url: window['groundhogg_endpoints']?.contacts.split('?')[0],
				dataType: 'json',
				delay: 250,
				beforeSend: function (xhr) {
					/* global groundhogg_nonces */
					xhr.setRequestHeader('X-WP-Nonce', groundhogg_nonces._wprest);
				},
				processResults: (data) => {
					const contactsArray = Array.isArray(data.contacts) ? data.contacts : Object.values(data.contacts);

					let processedResults = {
						results: [],
						pagination: {
							more: false,
						},
					};

					contactsArray.forEach(contact => {
						processedResults.results.push({
							id: contact.ID,
							text: `${contact.data.first_name} ${contact.data.last_name} (${contact.data.email})`,
						});

						// Store the contact data in the global variable.
						if (!groundhoggContacts[contact.ID]) {
							groundhoggContacts[contact.ID] = contact;
						}
					});

					return processedResults;
				}
			},
			placeholder: 'Search for a contact',
		}
	).on('select2:select', (e) => populateGroupAdminOwnerFields($form, e)).on('select2:clear select2:unselect', () => resetGroupAdminOwnerFields($form));

	// On page load, set the email field to read only if the existing contact field is populated.
	if ($existingContact.val() && $existingContact.val().length > 0) {
		setFieldToReadOnly($form.querySelector('input#email'));
	}
}

/**
 * Set the minimum and maximum number of seats based on the membership type.
 *
 * @param $form The form element.
 * @return void
 */
function setNumberOfSeatsBasedOnMembershipType($form) {
	const $membershipType = $form.querySelector('input[name=membership_type]:checked');
	const $numberOfSeats = $form.querySelector('input[name=number_of_seats]');

	if (!$membershipType || !$numberOfSeats) {
		return;
	}

	let minSeats = $membershipType.dataset.minSeats;
	let maxSeats = $membershipType.dataset.maxSeats;

	if (minSeats === undefined || maxSeats === undefined) {
		alert('There was an error determining how many seats will be available. Please try again. If this issue persists, please contact support.');
		return;
	}

	// Unlimited seats.
	const $tableRowStyles = $numberOfSeats.closest('tr').style;
	if (minSeats === '' && maxSeats === '') {
		setFieldToReadOnly($numberOfSeats);
		$tableRowStyles.display = 'none';
		$numberOfSeats.value = '';

		$numberOfSeats.removeAttribute('min');
		$numberOfSeats.removeAttribute('max');

		return;
	}

	setFieldToReadOnly($numberOfSeats, false);
	$tableRowStyles.removeProperty('display');

	// Make sure there are sensible values.
	minSeats = Math.max(1, minSeats.length ? parseInt(minSeats) : 1);
	maxSeats = Math.max(0, maxSeats.length ? parseInt(maxSeats) : 0);

	if (minSeats > 0) {
		$numberOfSeats.setAttribute('min', minSeats.toString());
	} else {
		$numberOfSeats.removeAttribute('min');
	}

	if (maxSeats > 0) {
		$numberOfSeats.setAttribute('max', maxSeats.toString());
	} else {
		$numberOfSeats.removeAttribute('max');
	}
}

/**
 * Set the email field to read only.
 *
 * @param {null|HTMLInputElement|HTMLSelectElement} $field
 * @param {boolean} setReadOnly Whether to set the email field to read only.
 *
 * @return void
 */
function setFieldToReadOnly($field, setReadOnly = true) {
	if (!$field) {
		console.warn('Tried to set a field to read only, but no field was passed in');
		return;
	}

	if (setReadOnly) {
		$field.setAttribute('readonly', 'readonly');
	} else {
		$field.removeAttribute('readonly');
	}
}

/**
 * Populate the group admin fields.
 *
 * @param {HTMLElement} $form The form element.
 * @param {Event} e The event object.
 *
 * @return void
 */
function populateGroupAdminOwnerFields($form, e) {
	const contactId = e.currentTarget.value;

	if (!contactId || !groundhoggContacts[contactId]) {
		return;
	}

	const contact = groundhoggContacts[contactId];

	const contactFields = {
		'first_name': contact.data?.first_name,
		'last_name': contact.data?.last_name,
		'email': contact.data?.email,
		'company_name': contact.meta?.company_name,
		'address_line_1': contact.meta?.street_address_1,
		'address_line_2': contact.meta?.street_address_2,
		'suburb': contact.meta?.city,
		'state': contact.meta?.region,
		'postcode': contact.meta?.postal_zip,
		'country': contact.meta?.country
	};

	Object.keys(contactFields).forEach(field => {
		const input = $form.querySelector(`input#${field},select#${field}`);
		if (input) {
			input.value = contactFields[field] || '';
		}
	});
	setFieldToReadOnly($form.querySelector('input#email'));
}

/**
 * Reset the group admin fields.
 *
 * @param {HTMLElement} $form The form element.
 *
 * @return void
 */
function resetGroupAdminOwnerFields($form) {
	const fields = $form.querySelectorAll('.ams-common-group-admin-field input, .ams-common-group-admin-field select');
	fields.forEach(field => {
		field.value = '';
	});

	// The email field needs to set to editable.
	setFieldToReadOnly($form.querySelector('input#email'), false);
}

function showAddMembersForm(event) {
	event.preventDefault();
	const $form = document.querySelector('.ams-common-add-new-group-members-form');
	if (!$form) {
		return;
	}

	// Get the clicked button (probably the event.target itself...).
	const $button = event.target.closest('button');

	// Show the form.
	$form.style.display = 'block';

	// Hide the button.
	$button.style.display = 'none';

	// Click the "add member" button to give a first row...
	$form.querySelector('#add_group_member').click();
}

/**
 * Add a repeater for the add new group members form.
 *
 * @param {string} buttonSelector The button selector.
 * @param {string} formSelector The form selector.
 * @param {string} rowTemplateSelector The row template selector.
 *
 * @return void
 */
function addRepeaterForAddNewGroupMembersForm(buttonSelector, formSelector, rowTemplateSelector) {
	const $button = document.querySelector(buttonSelector);
	const $form = document.querySelector(formSelector);
	const $rowTemplate = document.querySelector(rowTemplateSelector);

	if (!$button || !$form || !$rowTemplate) {
		return;
	}

	const $tableBody = $form.querySelector('table tbody');


	const limit = parseInt($form.getAttribute('data-limit') || 0);
	const count = parseInt($form.getAttribute('data-count') || 0);

	const addNewRow = function () {
		// Add a new row to the table.
		$tableBody.insertAdjacentHTML('beforeend', $rowTemplate.innerHTML.trim());

		const $lastRow = $tableBody.querySelector('tr:last-child');

		const $emailInput = $lastRow.querySelector('[type=email]');
		const $firstNameInput = $lastRow.querySelector('[name*=first_name]');
		const $lastNameInput = $lastRow.querySelector('[name*=last_name]');
		const $contactSelector = $lastRow.querySelector('select.groundhogg-contact-selector');

		if (!$emailInput || !$contactSelector) {
			return;
		}

		$emailInput.setAttribute('type', 'hidden');

		const $$contactSelector = jQuery($contactSelector);

		const setEmptyValidity = () => {
			$contactSelector.setCustomValidity('Please specify an email address.');
		};

		setEmptyValidity();

		$$contactSelector
			.select2({
				tags: true,
				width: null,
				allowClear: true,
				placeholder: 'Search for a contact, or enter a new email address',
				ajax: {
					/* global groundhogg_endpoints */
					url: window['groundhogg_endpoints']?.contacts.split('?')[0],
					dataType: 'json',
					delay: 250,
					beforeSend: function (xhr) {
						/* global groundhogg_nonces */
						xhr.setRequestHeader('X-WP-Nonce', groundhogg_nonces._wprest);
					},
					processResults: (data) => {
						const contactsArray = Array.isArray(data.contacts) ? data.contacts : Object.values(data.contacts);

						let processedResults = {
							results: [],
							pagination: {
								more: false,
							},
						};

						contactsArray.forEach(contact => {
							processedResults.results.push({
								id: contact.ID,
								text: `${contact.data.first_name} ${contact.data.last_name} (${contact.data.email})`,
							});

							// Store the contact data in the global variable.
							if (!groundhoggContacts[contact.ID]) {
								groundhoggContacts[contact.ID] = contact;
							}
						});

						return processedResults;
					}
				},
			})
			.on('change', changeEvent => {
				$contactSelector.setCustomValidity('');
				const contactId = $contactSelector.value;

				if (!contactId) {
					// Clear any values, in case someone "x"d the selected contact.
					$emailInput.value = '';
					if ($firstNameInput) {
						$firstNameInput.value = '';
					}
					if ($lastNameInput) {
						$lastNameInput.value = '';
					}
					setEmptyValidity();
					return;
				}

				let email = '';
				let firstName = '';
				let lastName = '';

				// If it's not a number, maybe it was an email address for a new contact?
				if (Number.isNaN(Number(contactId))) {
					email = contactId;
				} else {
					// ...otherwise try and get the contact.
					const contact = groundhoggContacts[contactId] || null;

					if (!contact) {
						alert('Sorry, we were unable to load the contact details at this time.\nPlease reload and try again.')
						$$contactSelector.val(null).trigger('change');
						return;
					}

					email = contact.data.email;
					firstName = contact.data.first_name;
					lastName = contact.data.last_name;
				}

				const emailValidationErrorMessage = getEmailValidationError(email);
				if (emailValidationErrorMessage) {
					window.setTimeout(() => {
						$contactSelector.setCustomValidity(emailValidationErrorMessage);
						$contactSelector.reportValidity();
					}, 100);
					return;
				}

				$emailInput.value = email;
				if ($firstNameInput) {
					$firstNameInput.value = firstName;
					// Set readonly if we had a first/last name...
					setFieldToReadOnly($firstNameInput, !!firstName);
				}
				if ($lastNameInput) {
					$lastNameInput.value = lastName;
					// Set readonly if we had a first/last name...
					setFieldToReadOnly($lastNameInput, !!lastName);
				}
			});


	};

	// Add a new row when the button is clicked.
	$button.addEventListener('click', function (e) {
		e.preventDefault();

		// Only add a new row if the limit is not reached.
		let totalCount = $tableBody.querySelectorAll('tr').length + count;
		if (limit > 0 && totalCount === limit) {
			alert('You have reached the maximum number of members that can be added.');
			return;
		}

		addNewRow();
	});

	// Remove the row when the remove link is clicked.
	$tableBody.addEventListener('click', function (e) {
		if (e.target.matches('a.remove-row')) {
			e.preventDefault();
			e.target.closest('tr').remove();
		}
	});
}
