/**
 * Stores the contact results from GH contact AJAX searches.
 * @type {{}}
 */
const contactCache = {};


/**
 * Initializes repeaters within the provided element. This looks for <button>s
 * which have a `data-ams-repeats-template` attribute. For those which do have
 * that attribute, if a <template> exists in the $container with the matching CSS
 * selector, that <template> will be used for generating new HTML when clicked.
 * The location of the new HTML is _BEFORE_ the <template>.
 *
 * When a new <template> is instantiated, a custom `ams.repeater.created` event
 * is fired.
 *
 * @todo: In the future, this could be refactored out to be used in multiple places - it's agnostic, but here for now...
 */
function setupRepeaters($container) {
	const $buttons = $container.querySelectorAll('button[data-ams-repeats-template]');

	if (!$buttons.length) {
		return;
	}

	$buttons.forEach($button => {
		const templateSelector = $button.dataset.amsRepeatsTemplate;

		// No template attribute, remove and bail.
		if (!templateSelector) {
			$button.remove();
			return;
		}

		/** @var {HTMLTemplateElement} **/
		const $template = $container.querySelector(templateSelector);

		if (!$template) {
			$button.remove();
			return;
		}

		// Only accept a single child in the <template>.
		if ($template.content.childElementCount !== 1) {
			console.warn('Sorry, <template> must have a single child.', {$template});
			$button.remove();
			return;
		}

		// When the button is clicked, create the new element.
		$button.addEventListener('click', event => {
			event.preventDefault();

			const $newElement = $template.content
				.cloneNode(true)
				.firstElementChild;

			$template.before($newElement);

			$newElement.dispatchEvent(new CustomEvent('ams.repeater.created', {
				bubbles: true,
				detail: {$newElement, $container, $template}
			}));
		});

		// If the button had a predefined value provided, make sure that's setup and populated. we can easily do that
		// by listening for our event on the template's parent, then removing that event after.
		try {
			const values = JSON.parse($button.dataset.amsInitialRepeaterFieldValues || 'null');
			if (Array.isArray(values)) {
				let currentIndex = -1;
				const setInitialValues = createdEvent => {
					currentIndex++;
					const $newElement = createdEvent.detail.$newElement;

					if (!$newElement) {
						return;
					}

					const fieldValues = values[currentIndex] || null;

					if (!fieldValues) {
						return;
					}

					Object.entries(fieldValues).forEach(entry => {
						const $field = $newElement.querySelector(`[name="${entry[0]}"]`);
						if ($field) {
							$field.value = entry[1];
							$field.dispatchEvent(new Event('change', {bubbles: true}));
						}
					})
				}

				$template.parentElement.addEventListener('ams.repeater.created', setInitialValues);
				// Click the button for every value.
				for (let i = 0; i < values.length; i++) {
					$button.click();
				}
				$template.parentElement.removeEventListener('ams.repeater.created', setInitialValues);

				// Populate the values
			}
		} catch (exception) {
			console.error('Error populating pre-provided values for repeater', {$button, $template});
		}

	});
}

/**
 * Setup the container so that it listens for element removal events, via click. This
 * is probably bound to buttons to remove parent elements (eg, a repeater).
 *
 * @param {HTMLElement} $container
 */
function setupRemovers($container) {
	$container.addEventListener('click', event => {
		const $remover = event.target.closest('[data-ams-remove-closest-on-click]');

		if (!$remover) {
			return;
		}

		const selector = $remover.dataset.amsRemoveClosestOnClick;

		if (!selector) {
			return;
		}

		const $candidate = $remover.closest(selector);

		if (!$candidate) {
			return;
		}

		// It's not a field, but let's dispatch a change event, so that things like validation can kick off
		// anyway.
		$candidate.dispatchEvent(new Event('change', {bubbles: true}));

		$candidate.remove();
	});
}

/**
 * Look for elements matching selector `[data-ams-contact-selector]`. Supports `input` and `select`. If it is an `input`
 * then it will insert an adjacent selector to support the functionality. the `input` will have its type changed
 * to `hidden` in that case.
 * @param {HTMLElement} $container
 */
function setupContactSelectors($container) {
	if (typeof jQuery.fn.select2 !== 'function') {
		console.error('Select2 is required for the contact selectors...');
		return;
	}

	if (!window['groundhogg_endpoints']?.contacts) {
		console.warn('Groundhogg contacts endpoint was missing.');
		return;
	}

	const $candidates = $container.querySelectorAll('[data-ams-contact-selector]');

	if (!$candidates.length) {
		return;
	}

	$candidates.forEach($candidate => {
		const candidateIsSelect = $candidate.tagName === 'SELECT';

		// Get the select, or if the candidate is not a select field, create one (to be appended).
		const $select = candidateIsSelect ? $candidate : document.createElement('select');


		if (!candidateIsSelect) {
			const preselectedValue = ($candidate.value || '').trim();

			if (preselectedValue) {
				// Make sure the new select has at least one option, which might be the current value, if there was one
				// provided with the original field.
				const $defaultOption = document.createElement('option');
				$defaultOption.value = preselectedValue;
				$defaultOption.innerText = preselectedValue;
				$defaultOption.setAttribute('selected', 'selected');
				$select.append($defaultOption);
			}

			$candidate.after($select);
			$candidate.setAttribute('type', 'hidden');
		}

		// Bind the AJAX search behaviour.
		const $$select2 = jQuery($select).select2({
			width: null,
			allowClear: true,
			ajax: {
				// Url with no query string...
				url: window.groundhogg_endpoints.contacts.replace(/\?.*/g, ''),
				dataType: 'json',
				delay: 250,
				beforeSend: xhr => {
					xhr.setRequestHeader('X-WP-Nonce', groundhogg_nonces._wprest);
				},
				processResults: data => {
					const contacts = Array.isArray(data.contacts)
						? data.contacts
						: Object.values(data.contacts);

					const results = contacts.map(contact => {
						const id = contact?.ID || -1;

						// Pop it into the cache for easier reference (mostly with selection of values...)
						if (!contactCache[id]) {
							contactCache[id] = contact;
						}

						return {
							id,
							text: `${contact?.data?.first_name || '(No first name)'} ${contact?.data?.last_name || '(No last name)'} (${contact?.data?.email || 'No email'})`,
						};
					});

					const pagination = {more: false};

					return {results, pagination};
				},
			},
			placeholder: $candidate.hasAttribute('placeholder')
				? $candidate.getAttribute('placeholder')
				: ''
		});

		const $supportingFields = $container.querySelectorAll('[data-ams-contact-prop]');

		const clearSupportingFields = () => {
			$supportingFields.forEach($field => {
				$field.value = '';
				$field.removeAttribute('readonly');
				$field.dispatchEvent(new Event('change', {bubbles: true}));
			});
		};

		const populateSupportingFields = (contact) => {
			console.log({contact})
			$supportingFields.forEach($field => {
				const prop = ($field.dataset.amsContactProp || '').split('.');

				if (prop.length !== 2 || !prop[0] || !prop[1]) {
					return;
				}

				if (prop[0] !== 'data' && prop[0] !== 'meta') {
					return;
				}

				const value = contact[prop[0]][prop[1]];

				if (typeof value === 'undefined') {
					return;
				}

				$field.value = value;
				$field.dispatchEvent(new Event('change', {bubbles: true}));
			});
		}

		// When selected, populate other fields.
		$$select2
			.on('select2:select', event => {
				const id = parseInt(event.currentTarget.value);

				clearSupportingFields();
				populateSupportingFields(contactCache[id] || {});
				$candidate.classList.add('contact-selected');
			})
			.on('select2:clear select2:unselect', event => {
				clearSupportingFields();
				$candidate.classList.remove('contact-selected');
			});
	});
}

/**
 * @param {HTMLElement} $container
 */
export function setupStateSelection($container) {
	const $countrySelector = $container.querySelector('#ams_individual_membership_form_country');
	const $stateSelector = $container.querySelector('#ams_individual_membership_form_state');

	const stateSelectorIsRequiredByDefault = $stateSelector.hasAttribute('required');

	if (!$countrySelector || !$stateSelector) {
		console.warn('Missing country or state to setup the behaviour for state selections...', {
			$countrySelector,
			$stateSelector
		});
		return;
	}

	// All groups, except the placeholder one.
	const $optionGroups = $stateSelector.querySelectorAll('optgroup:not([label=""])')

	// We need to maintain the original value...
	const initialStateValue = $stateSelector.value;

	$countrySelector.addEventListener('change', event => {
		$stateSelector.value = '';

		$optionGroups.forEach($group => $group.dataset.amsCountryIsSelected = 'no');
		const $stateGroup = $stateSelector.querySelector(`optgroup[label="${$countrySelector.value}"]`);

		if ($stateGroup) {
			$stateGroup.dataset.amsCountryIsSelected = 'yes';
		}

		if (!$stateSelector.checkVisibility()) {
			$stateSelector.removeAttribute('required');
		} else {
			// Return the original state field's required attribute if necessary.
			if (stateSelectorIsRequiredByDefault) {
				$stateSelector.setAttribute('required', 'required');
			}
		}
	});

	$countrySelector.dispatchEvent(new Event('change', {bubbles: true}));

	$stateSelector.value = initialStateValue;
	$stateSelector.dispatchEvent(new Event('change', {bubbles: true}));
}

/**
 * @param {HTMLFormElement} $form
 */
function setupFormValidation($form) {
	// General form validation on submit.
	$form.addEventListener('submit', event => {
		// Check at least one subscription field is present. Field validation will catch invalid values on those
		// when they are present, but if there are none the form might submit, so we need to check the presence
		// of that ourselves...
		const $subscriptionField = $form.querySelector('[name="subscription_product[]"]');

		if (!$subscriptionField) {
			event.preventDefault();
			alert('A membership must be specified for the contact.');
			return false;
		}
	});

	// Async plan validation here, for nicer warnings/handling. Essentially we'll just POST when the form values change,
	// but also specify a var to tell server-side that we just want to get the plan warnings/errors.

	let debouncedCallback = null;

	// Track number of loading events so we can properly manage the spinner/working state asynchronously.
	let loadingStack = 0;

	$form.addEventListener('change', event => {
		$form.classList.add('working');

		window.clearTimeout(debouncedCallback);
		debouncedCallback = window.setTimeout(() => {
			loadingStack++;

			const data = {
				validatePlanOnly: true,
			};

			(new FormData($form)).entries().forEach(entry => {
				const key = entry[0];
				const value = entry[1];

				// If it's an array style field, handle that
				if (/\[]$/.test(key)) {
					const arrayPropertyKey = key.replace('[]', '');

					if (typeof data[arrayPropertyKey] === 'undefined') {
						data[arrayPropertyKey] = [];
					}

					data[arrayPropertyKey].push(value);
				} else {
					data[key] = value;
				}
			});

			jQuery.ajax({
				type: $form.getAttribute('method') || 'POST',
				url: $form.getAttribute('action') || window.location,
				data,
			})
				.done(data => {
					const responseHtml = data || '';

					if (!responseHtml || typeof responseHtml !== 'string') {
						console.warn('no responseHtml')
						return;
					}

					const $html = jQuery(responseHtml);
					const $newGeneralErrors = $html.find('.ams-create-individual-membership-form__general-errors');

					if (!$newGeneralErrors.length) {
						return;
					}

					const $currentGeneralErrors = $form.querySelector('.ams-create-individual-membership-form__general-errors');
					$currentGeneralErrors.innerHTML = $newGeneralErrors.html();
				})
				.fail(error => {
					console.error('There was an error asynchronously validating the form.', {error});
				})
				.always(() => {
					loadingStack--;

					if (!loadingStack) {
						$form.classList.remove('working');
					}
				});


		}, 1000);
	});
}

export function setupIndividualMembershipForm() {
	const $form = document.querySelector('#ams-create-individual-membership-form');

	if (!$form) {
		return;
	}

	setupContactSelectors($form);
	setupStateSelection($form);
	setupFormValidation($form);
	setupRepeaters($form);
	setupRemovers($form);
}
