function maybeRemoveConfirmField($confirmCheckbox, $planContainer) {
	if (!$confirmCheckbox || !$planContainer) {
		return;
	}

	const $errorRow = $planContainer.querySelector('.ams-common-membership-contact-plan-action__row--errors');

	if (!$errorRow) {
		return;
	}

	// Discover the parent row from the checkbox, and remove.
	$confirmCheckbox.closest('[data-name="ams_create_woo_subscriptions_confirm_action_plan"]')?.remove();

}

export function setupMembershipPlanPreview() {
	if (!jQuery) {
		return;
	}


	if (!window['ams_common_woo_membership_plan_urls']) {
		return;
	}

	const $placeholder = document.querySelector('.ams-common-create-memberships-plan-placeholder');

	if (!$placeholder) {
		return;
	}

	const ajaxUrl = window['ams_common_woo_membership_plan_urls'].ajax_url || false;

	if (!ajaxUrl) {
		console.warn('Wanted to load the Membership Plan preview, but the URL was not present...');
		return;
	}

	let success = false;

	const runPlansLabel = window['ams_common_woo_membership_plan_urls']?.messages?.run_plans_publish_label;
	const confirmCheckboxId = window['ams_common_woo_membership_plan_urls']?.ids?.confirm_action_plan_checkbox;
	const $confirmCheckbox = confirmCheckboxId ? document.querySelector(`#${confirmCheckboxId}`) : null;
	const $publishButton = document.querySelector('input[type="submit"]#publish');

	// If we found the confirm checkbox and the publish button, setup the label change for the button.
	if ($confirmCheckbox && $publishButton) {
		const updatePlansLabel = $publishButton.value;

		$confirmCheckbox.addEventListener('change', event => {
			$publishButton.value = $confirmCheckbox.matches(':checked')
				? runPlansLabel
				: updatePlansLabel;
		});
	}

	jQuery.ajax({
		type: 'post',
		dataType: 'json',
		url: ajaxUrl,
		data: {action: 'get_ams_common_woo_memberships_plan'},
	})
		.then(response => {
			success = response.success || true;
			const $plan = jQuery(response.data);
			jQuery($placeholder).after($plan).remove();
			maybeRemoveConfirmField($confirmCheckbox, $plan.get(0));
		})
		.always(() => {
			if (!success) {
				$placeholder.innerText = window['ams_common_woo_membership_plan_urls']?.messages?.error_loading_preview || 'Error loading preview';
			} else {
				$placeholder.remove();
			}
		});

}
