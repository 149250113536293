import {makeActivityRenderer} from "../activity-renderer-factory.js";

export const uploadFormFileToContactFailedActivityRenderer = makeActivityRenderer(
	activity => {
		// Get WP's translation function.
		const translate = window.wp.i18n.__;

		// Get the meta of the activity.
		const meta = activity?.meta || {};

		const title = translate(`Could not upload a file to the contact from form submission: <em>${meta.form_title} &raquo; ${meta.field_label}</em>`, 'ams-common');

		return `
				<h6>${title}</h6>
				<table>
				<tr>
					<th>Success</th>
					<td>${meta.success ? 'true' : 'false'}</td>
				</tr>
				<tr>
					<th>Message</th>
					<td>${meta.message || '(no message)'}</td>
				</tr>
				<tr>
					<th>Form</th>
					<td>${meta.form_title || translate('Form title unknown')} (ID: ${meta.form_id || translate('Form ID unknown')})</td>
				</tr>
				<tr>
					<th>Feed</th>
					<td>${meta.feed_name || translate('Feed name unknown')} (ID: ${meta.feed_id || translatea('Feed ID unknown')})</td>
				</tr>
				<tr>
					<th>Entry</th>
					<td><a href="${encodeURI(meta.entry_link || translate('Entry link missing'))}">View entry</a></td>
				</tr>
				</table>
			`;
	},
	{
		iconFramed: true,
		icon: window.Groundhogg?.element?.icons?.save || '📂',
	}
);
